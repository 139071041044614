import React from "react";
// import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import styles from "../../assets/styles/viaja-gratis.module.css";
import ImgPortada from "../../assets/images/viajaGratisPortada.jpg";
import ImgLeft from "../../assets/images/viajaGratis1.jpg";
import ImgRight from "../../assets/images/viajaGratis2.jpg";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& > *": {
//       margin: theme.spacing(1),
//     },
//   },
//   btnFAQ: {
//     borderColor: "#303964",
//     color: "#303964",
//     fontWeight: "bold",
//     "&:hover": {
//       backgroundColor: "#303964",
//       color: "white",
//     },
//   },
// }));

export default function FreeTravels() {
  // const classes = useStyles();

  return (
    <div>
      <div>
        <div className={styles.parallax_section}>
          <div className="container p-5 d-flex flex-column align-items-center">
            <h1 className="text-white text-center font-weight-bold titulo-viaje">
              Viaja Gratis o un Viaje Personalizado
            </h1>
            <div className="text-white text-center">
              Viaja gratis con nosotros u obtén un viaje personalizado, solo
              tienes que ver las opciones en la parte inferior.
            </div>
          </div>
        </div>
        <div className={styles.portadaViajaGratis}>
          <img className={styles.parallax_img} src={ImgPortada} alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row my-5">
          <div className="col-md-4 pt-3 pt-md-0">
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "14px",
              }}
              src={ImgLeft}
              alt=""
            />
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-7 order-first order-md-last text-left">
            <div
              className="h1 font-weight-bold text-md-left text-center mt-4"
              style={{ color: "#303964" }}
            >
              Viaja Gratis
            </div>
            <div className="text-md-left text-center">
              Con nosotros te ofrecemos la posibilidad de formar tu propio grupo
              y obtener tu viaje gratis, así como convertirte en un asociado y
              obtener comiciones por trabajar con nosotros.
            </div>
            <div>
              <TextField
                fullWidth
                type="text"
                id="nombre"
                label="Nombre Completo"
                variant="standard"
                // value={data.gratisNombre}
                // onChange={(e) => {
                //   setData("gratisNombre", e.target.value);
                // }}
              />
              <div className="row mt-3">
                <div className="col-md-6">
                  <TextField
                    fullWidth
                    id="telefono"
                    label="Teléfono de Contacto"
                    variant="standard"
                    // error={!!data.gratisTelefono ? false : true}
                    // value={data.gratisTelefono}
                    // onChange={(e) => {
                    //   setData("gratisTelefono", e.target.value);
                    // }}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    fullWidth
                    inputProps={{
                      pattern:
                        "/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/",
                    }}
                    id="correo"
                    type="email"
                    label="Correo Electrónico"
                    variant="standard"
                    // value={data.gratisCorreo}
                    // onChange={(e) => {
                    //   setData("gratisCorreo", e.target.value);
                    // }}
                  />
                </div>
              </div>
              <Button
                variant="outlined"
                className={`btn-lg mt-3 btn-block ${styles.btnFAQ}`}
                // onClick={handleSubmit}
              >
                CONTRATAR
              </Button>
              <div></div>
            </div>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-7">
            <div
              className="h1 font-weight-bold text-md-right text-center mt-4"
              style={{ color: "#303964" }}
            >
              Viajes Personalizados
            </div>
            <div className="text-md-right text-center">
              Usted nos dice a donde quiere ir y nosotros nos encargamremos de
              todo, al mejor precio y la mejor atención A continuación escriba
              los lugares a los que le gustaría ir.
            </div>
            <div>
              <TextField
                fullWidth
                id="nombre"
                label="Nombre Completo"
                variant="standard"
                // value={data.persNombre}
                // onChange={(e) => {
                //   setData("persNombre", e.target.value);
                // }}
              />
              <div className="row mt-3">
                <div className="col-md-6">
                  <TextField
                    fullWidth
                    id="telefono"
                    label="Teléfono de Contacto"
                    variant="standard"
                    // value={data.persTelefono}
                    // onChange={(e) => {
                    //   setData("persTelefono", e.target.value);
                    // }}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    fullWidth
                    id="correo"
                    label="Correo Electrónico"
                    variant="standard"
                    // value={data.persCorreo}
                    // onChange={(e) => {
                    //   setData("persCorreo", e.target.value);
                    // }}
                  />
                </div>
              </div>
              <TextField
                className="mt-3"
                fullWidth
                multiline
                rows={4}
                id="mensaje"
                label="Mensaje"
                variant="standard"
                // value={data.persMensaje}
                // onChange={(e) => {
                //   setData("persMensaje", e.target.value);
                // }}
              />
              <Button
                variant="outlined"
                className={`btn-lg mt-3 btn-block ${styles.btnFAQ}`}
              >
                CONTRATAR
              </Button>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-4 pt-3 pt-md-0">
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "14px",
              }}
              src={ImgRight}
              alt=""
            />
          </div>
        </div>
      </div>
      {/* <Snackbars /> */}
    </div>
  );
}
