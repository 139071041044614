import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";

function MainLayout(props) {
  return (
    <div>
      <Navbar />
      <div style={{ heigth: "90vh" }}> <Outlet /></div>
      <div style={{ marginTop: "180px" }}>
        <Footer />
      </div>
      
    </div>
  );
}

export default MainLayout;
