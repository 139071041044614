import React from "react";
import styles from "../../../assets/styles/sobre_nosotros.module.css";
import ImgOver1 from "../../../assets/images/about_1.jpg";
import ImgOver2 from "../../../assets/images/about_2.jpg";

function SobreNosotros() {
  return (
    <div className="container pt-5">
      <div className="row">
        <div className="col-md-6 pr-md-5 text-center text-md-start align-self-center">
          <h1 className={styles.titulo}>SOBRE NOSOTROS</h1>
          <p className={styles.texto}>
            MERLOP TRAVEL es una compañía fundada en los mas altos valores del
            Turismo religioso. Su dueño y fundador el Reverendo Marco Antonio
            Mercado López cuenta con una experiencia de más de 20 años como
            sacerdote activo en la Iglesia Católoca y 5 años en el mundo
            empresarial.
          </p>
        </div>
        <div className="col-md-6 ps-md-5">
          <img
            src={ImgOver1}
            className="img-fluid"
            style={{ borderRadius: "50px" }}
          />
        </div>
      </div>
      <div className="row pt-5">
        <div className="col-md-6 order-sm-2 pe-md-5">
          <img
            src={ImgOver2}
            className="img-fluid"
            style={{ borderRadius: "50px" }}
          />
        </div>
        <div className="col-md-6 pl-md-5 text-center text-md-end align-self-center order-first order-md-2">
          <h1 className={styles.titulo}>¿POR QUÉ ELEGIR MERLOP?</h1>
          <p className={styles.texto}>
            MERLOP TRAVEL conjuga los valores del mundo religioso de todas las
            denominaciones cristianas y no cristianas asi como agnósticos y no
            creyentes; con los más altos estándares del Turismo mundial, con un
            enfoque en Turismo religioso. MERLOP cuenta con alianzas
            estratégicas como “The Bible Comes to Life” en Israel y oficinas en
            los Estados Unidos, China y México.
          </p>
        </div>
      </div>
      {/* <div className="row pt-5">
        <iframe
          src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FPadreMarcoMercado%2Fvideos%2F310445413410220%2F&show_text=false&width=560&t=0"
          width="500px"
          height="800px"
          className="fbVideoGaleria"
          scrolling="no"
          frameborder="0"
          allowfullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          allowFullScreen="true"
        />
      </div> */}
    </div>
  );
}

export default SobreNosotros;
