import React from "react";
import { Link } from "react-router-dom";
import styles from "../assets/styles/navbar.module.css";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

function Navbar() {
  return (
    <nav
      className={`navbar navbar-expand-lg navbar-dark fixed-top ${styles.navbBg} `}
    >
      <div className="container-fluid">
        <a className="navbar-brand d-lg-none" href="#">
          MerlopTravel
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-center"
          id="navbarNavAltMarkup"
        >
          <ul className="navbar-nav">
            <Link
              className={"nav-link mx-3"}
              to={"/"}
              //   href={route("home")}
            >
              Home
            </Link>
            <Link
              className={"nav-link mx-3"}
              to="/galeria"
              //   href={route("galeria")}
            >
              Galería
            </Link>
            <Link
              className={"nav-link mx-3"}
              to="viajes"
              //   href={route("viajes.index")}
            >
              Viajes
            </Link>
            <Link
              className={"nav-link mx-3"}
              to="viaja_gratis"
              //   href={route("viajagratis")}
            >
              Viajes gratis/Personalizado
            </Link>
            <Link
              className={"nav-link mx-3"}
              to="faq"
              //   href={route("faq")}
            >
              Preguntas Frecuentes
            </Link>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
