import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import api from "../../api/client";
import styles from "../../assets/styles/viajes_pack.module.css";
import button_styles from "../../assets/styles/viaja-gratis.module.css";
import parse from "html-react-parser";
import { Button } from "@mui/material";
import ReservarFormDialog from "../../components/ReservarFormDialog";
import { FaCcVisa, FaCcMastercard } from "react-icons/fa";
import { SiZelle } from "react-icons/si";
import Backdrop from "../../components/Backdrop";

export default function TravelDetails() {
  const { uuid } = useParams();
  const [data, setData] = React.useState({});
  const [info, setInfo] = React.useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [reservate, setReservate] = useState(false);

  useEffect(() => {
    api
      .get("travels/" + uuid)
      .then((response) => {
        setData(response.data.data);
        setInfo(response.data.data.traveldetails[0]);
      })
      .catch((error) => {});
  }, [uuid]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <div>
        <div className={styles.parallax_section}>
          <div
            className={`container p-5 d-flex flex-column align-items-center ${styles.text}`}
          >
            <h1
              className={`text-white text-center font-weight-bold ${styles.titulo_viaje}`}
            >
              {data.title}
            </h1>
            <div className="text-white text-center h3 lead">
              {data.destinations}
            </div>
            <div className="text-white text-center">{data.description}</div>
          </div>
          <div className={styles.portadaGaleria}>
            <img
              className={styles.parallax_img}
              src={info.image_path_portrait}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className={`${styles.section2} container`}>
        <div className="row my-5">
          <div className="col-md-4">
            <img
              style={{
                width: "100%",
                height: "500px",
                objectFit: "cover",
                borderRadius: "14px",
              }}
              src={info.image_path_1}
              alt=""
            />
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-7 d-flex flex-column justify-content-center">
            <div
              className="text-md-left text-center font-weight-bold h1"
              style={{ color: "#303964" }}
            >
              ¿Qué incluye este paquete?
            </div>
            <div className="text-md-left text-center">
              Nuestro paquete incluye una gran variedad de servicios para
              ofrecerle al cliente la mejor experiencia. Algunos de los
              servicios que incluye este paquete son:
            </div>
            <div className="text-md-left font-italic my-3">
              {parse(info.includes ? info.includes : "")}
              {/* <ul>
                <li>Guía profesional</li>
                <li>Autobús y chofer de lujo</li>
                <li>Todas las propinas</li>
                <li>Noche en uno de los mejores hoteles del MAR MUERTO</li>
                <li>Entradas a los centros incluidos en el programa</li>
                <li>Equipo de audio para visitas</li>
                <li>Asistencia en llegada y salida</li>
                <li>Todas las comidas</li>
                <li>Seguro de saludo</li>
                <li>Guía espiritual y misa diaria</li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
      <div className="section3 container my-3">
        <div
          className="h1 font-weight-bold text-center"
          style={{ color: "#303964" }}
        >
          Itinerario
        </div>
        <div className="d-flex justify-content-center my-3">
          <div
            className="mx-1"
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: "#303964",
            }}
          ></div>
          <div
            className="mx-1"
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: "#303964",
            }}
          ></div>
          <div
            className="mx-1"
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: "#303964",
            }}
          ></div>
          <div
            className="mx-1"
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: "#303964",
            }}
          ></div>
          <div
            className="mx-1"
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: "#303964",
            }}
          ></div>
        </div>
        <div className="row my-5">
          <div className="col-md-7 text-md-right text-left">
            {parse(info.itinerary_left ? info.itinerary_left : "")}
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "14px",
              }}
              src={info.image_path_2}
              alt=""
            />
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-4">
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "14px",
              }}
              src={info.image_path_3}
              alt=""
            />
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-7 order-first order-md-last text-left">
            {parse(info.itinerary_right ? info.itinerary_right : "")}
          </div>
        </div>
        <div
          className="h1 font-weight-bold text-center"
          style={{ color: "#303964" }}
        >
          Información
        </div>
        <div className="d-flex justify-content-center my-3">
          <div
            className="mx-1"
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: "#303964",
            }}
          ></div>
          <div
            className="mx-1"
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: "#303964",
            }}
          ></div>
          <div
            className="mx-1"
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: "#303964",
            }}
          ></div>
          <div
            className="mx-1"
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: "#303964",
            }}
          ></div>
          <div
            className="mx-1"
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: "#303964",
            }}
          ></div>
        </div>
        <div className="row">
          <div className="col-md-8">
            {/* <div className="h3 text-md-left text-center">Pagos</div> */}
            <div>{parse(info.info ? info.info : "")}</div>
            {/* <div className="h3 text-md-left text-center">¿Qué no incluye?</div>
            <div>
              <ul>
                <li>
                  Seguro de cancelación de viaje (recomendado $299.00 USD)
                </li>
                <li>Costo por salir de otro aeropuerto que no sea Chicago</li>
              </ul>
            </div> */}
            <div className="h3 text-md-left text-left">Formas de pago</div>
            <ul>
              <li>
                Tarjetas de crédito/débito{" "}
                <FaCcVisa size={"32px"} title="Visa" />{" "}
                <FaCcMastercard size={"32px"} />
              </li>
              <li>
                Zelle <SiZelle />
              </li>
            </ul>
            {/* <div className="row">
              <div className="col-2 col-md-1">
                <FaCcVisa size={"48px"} title="Visa"/>
              </div>
              <div className="col-2 col-md-1">
                <FaCcMastercard size={"48px"} />
                <a>Mastercard</a>
              </div>
              <div className="col-2 col-md-1">
                <FaMoneyBillAlt size={"48px"} />
              </div>
            </div> */}
          </div>
          <div className="col-md-4">
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "14px",
              }}
              src={info.image_path_4}
              alt=""
            />
          </div>
        </div>
        <div
          className="h1 font-weight-bold text-md-right text-center mt-4"
          style={{ color: "#303964" }}
        >
          ¡Reserva ahora!
          <div>
            <Button
              variant="outlined"
              className={`btn-lg mt-3 btn-block ${button_styles.btnFAQ}`}
              onClick={() => setOpenDialog(true)}
            >
              RESERVAR
            </Button>
          </div>
        </div>

        <div
          className="h1 font-weight-bold text-md-left text-center mt-4"
          style={{ color: "#303964" }}
        >
          ¿Aún tienes preguntas?
        </div>
        <div className="text-md-left text-center">
          <br />
          Visita nuestra sección de{" "}
          <Link to={"/faq"}>preguntas frecuentes.</Link>
        </div>
        {/* <Button variant="outlined" className={`btn-lg mt-3 ${classes.btnFAQ}`}>
          VER PREGUNTAS FRECUENTES
        </Button> */}
      </div>
      <ReservarFormDialog
        open={openDialog}
        handleClose={handleClose}
        uuid={uuid}
        reservate={reservate}
        setReservate={setReservate}
      />
    </div>
  );
}
