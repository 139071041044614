import React from "react";

import styles from "../../../assets/styles/sobre_nosotros.module.css";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";

import testimony1 from "../../../assets/images/Ferney.jpg";
import testimony2 from "../../../assets/images/Avatar2.jpg";
import testimony3 from "../../../assets/images/Mares.jpg";

export default function Testimonios() {
  return (
    <div className="container pt-5 viajesAltura">
      <div className="text-center">
        <h1 className={styles.titulo}>TESTIMONIOS</h1>
        <p className={styles.texto2}>
          Aquí algunos testimonios de clientes que han decidido embarcar el
          viaje con nosotros.
        </p>
      </div>
      <div className="row">
        <div className="col-md-4 pt-5 text-center">
          <img
            src={testimony1}
            alt=""
            style={{
              borderRadius: "50%",
              width: "150px",
              height: "150px",
              alignSelf: "center",
              objectFit: "cover",
            }}
          />
          <div className="pt-4 font-weight-bold">Ferney Ramirez</div>
          <div className="pt-4">
            <FormatQuoteIcon style={{ color: "#707070" }} />
            <small>
              Tuve la gran dicha de estar en Noviembre pasado en el viaje a
              Israel-Roma y la verdad fue una hermosa experiencia que jamas
              olvidare. El itinerario muy bien disenado, acomodados en
              Excelentes hoteles, con exquisita y abundante comida, un experto
              guia y un transporte de lujo, hicieron que el tour fuera
              espectacular. Pero lo mas significativo de todo, la amabilidad y
              alegria del Padre Marco, su afan por atendernos lo mejor posible,
              sin duda hicieron que todos nos sintieramos en familia.
            </small>
          </div>
          <div className="pt-4">
            <StarIcon className="mr-2" style={{ color: "#FF9800" }} />
            <StarIcon className="mr-2" style={{ color: "#FF9800" }} />
            <StarIcon className="mr-2" style={{ color: "#FF9800" }} />
            <StarIcon className="mr-2" style={{ color: "#FF9800" }} />
            <StarIcon style={{ color: "#FF9800" }} />
          </div>
        </div>
        <div className="col-md-4 pt-5 text-center">
          <img
            src={testimony2}
            alt=""
            style={{
              borderRadius: "50%",
              width: "150px",
              height: "150px",
              alignSelf: "center",
              objectFit: "cover",
            }}
          />
          <div className="pt-4 font-weight-bold">Lupi Arenas</div>
          <div className="pt-4">
            <FormatQuoteIcon style={{ color: "#707070" }} />
            <small>
              La experiencia de viajar a Israel; Roma fue impresionante, e
              inolvidable! Nunca me habría imaginado la emoción de estar ahí!
              Los hoteles de hospedaje fueron excelentes, las exquisitas comidas
              incluidas, cada lugar que visitamos fue maravilloso, e incluyendo
              las compras de recuerdos fue muy divertido. Todo muy bien
              coordinado por Merlop Travel, el tour rebasó mis expectativas!
              Todo fue genial desde el primer día hasta el último momento! En
              definitiva creo que este viaje me ha enseñado mucha historia.
            </small>
          </div>
          <div className="pt-4">
            <StarIcon className="mr-2" style={{ color: "#FF9800" }} />
            <StarIcon className="mr-2" style={{ color: "#FF9800" }} />
            <StarIcon className="mr-2" style={{ color: "#FF9800" }} />
            <StarIcon className="mr-2" style={{ color: "#FF9800" }} />
            <StarHalfIcon style={{ color: "#FF9800" }} />
          </div>
        </div>
        <div className="col-md-4 pt-5 text-center">
          <img
            src={testimony3}
            alt=""
            style={{
              borderRadius: "50%",
              width: "150px",
              height: "150px",
              alignSelf: "center",
              objectFit: "cover",
            }}
          />
          <div className="pt-4 font-weight-bold">Gerardo Mares</div>
          <div className="pt-4">
            <FormatQuoteIcon style={{ color: "#707070" }} />
            <small>
              Hola mi nombre es Gerardo Mares y mi esposa Rosa ... para
              agradecer al padre Marco Mercado por el excelente viaje que
              realizamos en tierra Santa y la ciudad de Roma Italia los hoteles
              los desayunos y cenas los guías el transporte a todos los lugares
              que visitamos y sobre todo la amabilidad del Padre y su buen
              conocimiento usual paciencia para con nosotros gracias Padre Marco
              por todo, Dios lo bendiga y esperamos pronto volver a viajar con
              usted.
            </small>
          </div>
          <div className="pt-4">
            <StarIcon className="mr-2" style={{ color: "#FF9800" }} />
            <StarIcon className="mr-2" style={{ color: "#FF9800" }} />
            <StarIcon className="mr-2" style={{ color: "#FF9800" }} />
            <StarIcon className="mr-2" style={{ color: "#FF9800" }} />
            <StarIcon style={{ color: "#FF9800" }} />
          </div>
        </div>
      </div>
    </div>
  );
}
