import React from "react";
import Logo from "../../assets/images/Logo.png";
import SobreNosotros from "./sections/SobreNosotros";
import Galeria from "./sections/Galeria";
import ViajesProgramados from "./sections/ViajesProgramados";
import ViajesGratis from "./sections/ViajesGratis";
import Testimonios from "./sections/Testimonios";
import styles from "../../assets/styles/home.module.css";

function Home() {
  return (
    <div>
      <div className={styles.portadaHome}>
        <img src={Logo} alt="Merlop Travel" className={styles.logo} />
      </div>
      <SobreNosotros />
      <Galeria />
      <ViajesProgramados />
      <ViajesGratis />
      <Testimonios />
    </div>
  );
}

export default Home;
