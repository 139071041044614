import React from "react";
import { Select, FormControl, InputLabel, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";

function FormInputSelect({ name, control, label, options }) {
  const generateSelectOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl sx={{ m: 1, width: "100%" }} error={!!error} variant="standard">
          <InputLabel>{label}</InputLabel>
          <Select
            onChange={onChange}
            value={value}
            fullWidth
            error={error ? error.message : null}
          >
            {generateSelectOptions()}
          </Select>
        </FormControl>
      )}
    />
  );
}

export default FormInputSelect;
