import React, { useEffect } from "react";
import {
  BatteryChargingFull,
  CreditCard,
  BookOutlined,
  Coronavirus,
  HealthAndSafety
} from "@mui/icons-material";
import ViajesBackground from "../../assets/images/viajesBackground.jpg";
import api from "../../api/client";

import ViajesCard from "../../components/ViajesCard";
import styles from "../../assets/styles/viajes.module.css";

export default function Travels() {
  const [travels, setTravels] = React.useState([]);

  useEffect(() => {
    api
      .get("valid_travels")
      .then((response) => {
        setTravels(response.data.data);
      })
      .catch((error) => {
        console.log("travels error", error);
      });
  }, []);

  return (
    <div>
      <div>
        <div
          className="parallax-section"
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "50%",
            zIndex: "1",
          }}
        >
          <h1 className="text-white display-2">Viajes</h1>
        </div>
        <div className={styles.portadaViajes}>
          <img className="parallax-img" src={ViajesBackground} alt="" />
        </div>
      </div>
      <div className={`container mb-5 ${styles.contentViajes}`}>
        <div className="d-flex">
          <div className={`${styles.rectanguloTitulo} d-none d-md-block`}></div>
          <div className="pl-md-3">
            <div className="fw-bold h1 text-white text-md-start text-center textColor">
              ¿Qué incluye mi viaje?
            </div>
            <div className="text-white h6 text-md-left text-start textColor">
              Estas son algunas de las muchas cosas que se incluyen en el
              paquete que adquieras para tu viaje.
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-3 col-6 pt-3">
            <div
              className={`${styles.card_vijes_1} p-2 d-flex align-items-end flex-column grow`}
            >
              <div className="mt-auto">
                <div className="text-white fw-bold">Transporte</div>
                <div className="text-white">
                  Incluye todos los vuelos en clase económica, la aerolínea
                  dependerá del destino
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6 pt-3">
            <div
              className={`${styles.card_vijes_2} p-2 d-flex align-items-end flex-column grow`}
            >
              <div className="mt-auto">
                <div className="text-white fw-bold">Hoteles</div>
                <div className="text-white">
                  Incluye hoteles de calidad donde podremos descansar después de
                  cada guía turistica en la ciudad.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6 pt-3">
            <div
              className={`${styles.card_vijes_4} p-2 d-flex align-items-end flex-column grow`}
            >
              <div className="mt-auto">
                <div className="text-white fw-bold">Comidas</div>
                <div className="text-white">
                  Casi todos los viajes incluyen las 3 comidas, desayuno y cena
                  normalmente en los hoteles y el almuerzo durante los
                  recorridos disfrutando comida local.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6 pt-3">
            <div
              className={`${styles.card_vijes_3} p-2 d-flex align-items-end flex-column grow`}
            >
              <div className="mt-auto">
                <div className="text-white fw-bold">Tour</div>
                <div className="text-white">
                  Todos nuestros paquetes incluyen todas las entradas y tours
                  guiados todos los días del viaje.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="h1 fw-bold text-center" style={{ color: "#303964" }}>
            ¿Qué necesito llevar?
          </div>
          <div className="h6 text-center">
            Estas son algunas de las preguntas más frecuentes que nos hacen.
          </div>
          <div className="row mt-5 align-items-center justify-content-center">
            <div className="col-6 col-md-3 d-flex flex-column align-items-center text-center">
              <div className={`d-flex ${styles.circleIcons}`}>
                <Coronavirus fontSize="large" className="text-white" />
              </div>
              <div className="fw-bold mt-2">Covid-19</div>
              <div className="text-center">
                En el mundo actual casi todos los países del mundo requieren
                dosis completa de vacunación autorizada, y dependiendo el
                destino regulaciones especiales. Nosotros ayudamos con todos los
                procesos de regulación
              </div>
            </div>
            <div className="col-6 col-md-3 d-flex flex-column align-items-center text-center">
              <div className={`d-flex ${styles.circleIcons}`}>
                <HealthAndSafety fontSize="large" className="text-white" />
              </div>
              <div className="fw-bold mt-2">Seguro de viaje</div>
              <div className="text-center">
                En el mundo Covid en el que vivimos es fundamental contar con un
                seguro amplio de viaje, MERLOP ofrece algunos de los seguros más
                completos para proteger su dinero y brindarle tranquilidad,
                antes, y durante su viaje. Costo estándar del seguro $299.00 US
              </div>
            </div>
            <div className="col-6 col-md-3 d-flex flex-column align-items-center text-center">
              <div className={`d-flex ${styles.circleIcons}`}>
                <BookOutlined fontSize="large" className="text-white" />
              </div>
              <div className="fw-bold mt-2">Visa y Pasaporte</div>
              <div className="text-center">
                Merlop podrá ayudarle con cualquier duda sobre los
                requerimientos de visa, sin embargo la visa (y cualquier otro
                documento requerido por el lugar del destino) son
                responsabilidad única del pasajero. Pasaporte con vigencia de
                almenos 6 meses posterior a la salida es requisito indispensable
              </div>
            </div>
            <div className="col-6 col-md-3 d-flex flex-column align-items-center text-center">
              <div className={`d-flex ${styles.circleIcons}`}>
                <BatteryChargingFull fontSize="large" className="text-white" />
              </div>
              <div className="fw-bold mt-2">Electrónicos</div>
              <div className="text-center">
                Fuera de norteamerica (EUA y Canadá) casi todos los demás países
                usan voltaje 220. Normalmente los electrónicos mas usados
                (celular, tablet, laptop) vienen automáticamente listos para
                cualquier voltaje, favor de checar con nosotros para cualquier
                duda de voltaje “electricidad”. Merlop les obsequia siempre un
                adaptador de corriente
              </div>
            </div>
            <div className="col-6 col-md-3 d-flex flex-column align-items-center text-center">
              <div className={`d-flex ${styles.circleIcons}`}>
                <CreditCard fontSize="large" className="text-white" />
              </div>
              <div className="fw-bold mt-2">Tarjetas o Dinero</div>
              <div className="text-center">
                Es importante notificar a su proveedor de tarjeta de crédito o
                débito sobre su viaje para evitar bloqueos. Le recomendamos
                preguntar si su proveedor (banco) cobra por el uso de la tarjeta
                en el extranjero.
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row-reverse mt-5">
          <div
            className="rectanguloTitulo d-none d-md-block align-self-end"
            style={{ height: "80px" }}
          ></div>
          <div className="mr-3">
            <div
              className="font-weight-bold text-center text-md-end pt-5 h1"
              style={{ color: "#303964" }}
            >
              Próximos viajes
            </div>
            <div className="text-center text-md-end h6">
              Aquí se encuentran los viajes próximos a hacer, haz clic en el
              viaje que estés interesado para conocer los detalles.
            </div>
          </div>
        </div>
        <div className="row mt-5">
          {travels.map((travel, index) => {
            return (
              <div key={index} className="col-lg-4 col-md-6 pt-3">
                <ViajesCard data={travel} />
              </div>
            );
          })}
          {/* <ViajesCard /> */}
        </div>
      </div>
    </div>
  );
}
