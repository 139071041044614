import axios from "axios";

const baseURL = process.env.REACT_APP_API;

const api = axios.create({
  baseURL: baseURL,
});

// api.defaults.headers.common = {
//   Authorization: "Bearer " + encryptStorage.getItem("token"),
// };

export default api;
