import React from "react";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import img1 from "../assets/images/Travel7.jpg";
import "../assets/styles/viajes_card.css";
import "../assets/styles/animations.css";
import { useNavigate } from 'react-router-dom'
import NumberFormat from "react-number-format";

export default function ViajesCard({ travel }) {
  const navigate = useNavigate();
  return (
    <Card className="growCard" onClick={() => navigate(`/viajes/`.concat(travel?.uuid ? travel.uuid : 1))}>
      <CardActionArea>
        <div
          className="viajesCard"
          style={{
            backgroundImage: `linear-gradient(
                            rgba(0,0,0, .7),
                            rgba(0,0,0, .7)
                        ),url(${
                          travel?.image_path ? travel.image_path : img1
                        })`,
          }}
        >
          <div className="text-white text-center">
            <div className="h1 textViajesCard">
              {travel?.title ? travel.title : "Título del viaje"}
            </div>
            <div className="h2 textViajesCard">
              {travel?.destinations ? travel.destinations : "Destinos"}
            </div>
            <div className="h3 textViajesCard">
              <NumberFormat 
                displayType="text"
                value={travel?.price ? travel.price : "$9999.99USD"}
                thousandSeparator
                prefix="$"
              />
              <a> USD</a>
            </div>
            
          </div>
          <div className="h5 text-white">
            {(travel?.start_date
              ? travel.start_date
              : "0000/00/00") + ' / ' + (travel?.end_date
              ? travel.end_date
              : "0000/00/00")}
          </div>
          <div className="h6 text-white">CONOCER MÁS</div>
        </div>
      </CardActionArea>
    </Card>
  );
}
