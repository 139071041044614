import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
// import Galeria from "./pages/home/sections/Galeria";
import MainLayout from "./layouts/MainLayout";
import Gallery from "./pages/gallery/Gallery";
import Travels from "./pages/travels/Travels";
import FreeTravels from "./pages/free_travels/FreeTravels";
import Faq from "./pages/faq/Faq";
import TravelDetails from "./pages/travels/TravelDetails";
import GalleryPhotos from "./pages/gallery/GalleryPhotos";

function Rutas() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="/galeria" element={<Gallery />} />
          <Route path="/galeria/:uuid" element={<GalleryPhotos />} />
          <Route path="/viajes" element={<Travels />} />
          <Route path="/viajes/:uuid" element={<TravelDetails />} />
          <Route path="/viaja_gratis" element={<FreeTravels />} />
          <Route path="/faq" element={<Faq />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Rutas;
