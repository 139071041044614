import React from "react";
import { useNavigate } from "react-router-dom";

// import "../../../public/css/viajes_card_main.css";
import "../assets/styles/viajes_card_main.css";

export default function ViajesCard({ data }) {
  const navigate = useNavigate();

  return (
    <div
      className={`col container_foto`}
      onClick={() => navigate("./" + data.uuid)}
    >
      <div className={`text-center ver_mas`}>
        <div className={`text-white text-left px-4 pt-2 text-wrap textInfo`}>
          {data.description}
        </div>
      </div>
      <article className="text-left">
        <h2>{data.title}</h2>
        <h4>{data.destinations}</h4>
        <h4>{data.start_date + " || "+data.end_date}</h4>
        <h4>${data.price} USD</h4>
      </article>
      <img src={data.image_path} alt="" />
    </div>
  );
}
