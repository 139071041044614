import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { FormControl, TextField } from "@mui/material";

function FormInputText({ name, control, label, type }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value, ref },
        fieldState: { error },
        formState,
      }) => (
        <FormControl sx={{ m: 1, width: "100%"}}>
          <TextField
            helperText={error ? error.message : null}
            error={!!error}
            onChange={onChange}
            value={value}
            fullWidth
            label={label}
            type={type}
            variant="standard"
          />
        </FormControl>
      )}
    />
  );
}

FormInputText.propTypes = {
  name: PropTypes.string,
  control: PropTypes.any,
  label: PropTypes.string,
};

export default FormInputText;
