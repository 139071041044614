import React, { useEffect, useState } from "react";
import "../../assets/styles/faq.css";
import api from "../../api/client";
import faqPortada from "../../assets/images/faqBackground.jpg";
import Backdrop from "../../components/Backdrop";

export default function Faq() {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get("faqs")
      .then((response) => {
        setFaqs(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <div>
        <div
          className="parallax-section"
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "40%",
            zIndex: "1",
          }}
        >
          <h1 className="text-white text-center h1">Preguntas Frecuentes</h1>
        </div>
        <div className="portadaFaq">
          <img
            className="parallax-img"
            src={faqPortada}
            alt=""
            height={"100px"}
          />
        </div>
      </div>

      {faqs.map((faq, index) => {
        return (
          <div className="container my-4">
            <div className="faq-drawer" key={index}>
              <input
                className="faq-drawer__trigger"
                id={index === "0" ? "faq-drawer" : `faq-drawer-${index}`}
                type="checkbox"
              />
              <label className="faq-drawer__title" htmlFor={index === "0" ? "faq-drawer" : `faq-drawer-${index}`}>
                {faq.question}
              </label>
              <div className="faq-drawer__content-wrapper">
                <div className="faq-drawer__content">
                  <p>{faq.description}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <Backdrop open={loading} />
    </div>
  );
}
