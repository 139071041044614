import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormInputText from "../form-components/FormInputText";
import FormInputSelect from "../form-components/FormInputSelect";
import FormInputNumber from "../form-components/FormInputNumber";
import api from "../api/client";
import Message from "./Message";

function ReservarFormDialog({
  open,
  handleClose,
  uuid,
  setReservate,
  reservate,
}) {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [response, setResponse] = useState("");
  const schema = yup.object().shape({
    name: yup.string().max(255).required(),
    email: yup.string().email(),
    telefono: yup.string(),
    preferencia_contacto: yup.string(),
    preferencia_horario: yup.string(),
  });

  const { handleSubmit, control, setValue, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const opciones_contacto = [
    {
      label: "Por llamada telefonica",
      value: "Llamada",
    },
    {
      label: "Por correo electrónico",
      value: "Email",
    },
    {
      label: "Por WhatsApp",
      value: "Whatsapp",
    },
  ];

  const opciones_horario = [
    {
      label: "9:00 - 10:00",
      value: "9:00 - 10:00",
    },
    {
      label: "10:00 - 11:00",
      value: "10:00 - 11:00",
    },
    {
      label: "11:00 - 12:00",
      value: "11:00 - 12:00",
    },
    {
      label: "12:00 - 13:00",
      value: "12:00 - 13:00",
    },
    {
      label: "13:00 - 14:00",
      value: "13:00 - 14:00",
    },
    {
      label: "14:00 - 15:00",
      value: "14:00 - 15:00",
    },
    {
      label: "15:00 - 16:00",
      value: "15:00 - 16:00",
    },
    {
      label: "16:00 - 17:00",
      value: "16:00 - 17:00",
    },
    {
      label: "17:00 - 18:00",
      value: "17:00 - 18:00",
    },
    {
      label: "18:00 - 19:00",
      value: "18:00 - 19:00",
    },
    {
      label: "19:00 - 20:00",
      value: "19:00 - 20:00",
    },
    {
      label: "20:00 - 21:00",
      value: "20:00 - 21:00",
    },
  ];

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
        reset();
        handleClose();
      }, 3000);
    }
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [success, error]);

  const onSubmit = (data) => {
    let request = {
      name: data.name,
      email: data?.email ? data.email : undefined,
      telefono: data?.telefono ? data.telefono : undefined,
      travel: uuid,
      preferencia_contacto: data.preferencia_contacto,
      preferencia_horario: data.preferencia_horario,
    };

    setReservate(true);
    api
      .post("travels/reservate", request)
      .then((response) => {
        setReservate(false);
        setSuccess(true);
        setResponse(response.data.message);
      })
      .catch((e) => {
        setReservate(false);
        setError(true);
        setResponse(
          e.response?.data?.message
            ? e.response?.data?.message
            : "Ha ocurrido un error, por favor revise su conexión a internet he inténtelo de nuevo."
        );
      });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Datos de contacto</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Complete el siguiente formulario y nos pondremos en contacto a la
          brevedad posible.
        </DialogContentText>
        <form id="contact-me-form" onSubmit={handleSubmit(onSubmit)}>
          {success ? (
            <Message severity={"success"}>{response}</Message>
          ) : (
            error && <Message severity={"error"}>{response}</Message>
          )}
          {reservate ? (
            <div className="row">
              <div className="col-12">
                <CircularProgress color="secondary" />
              </div>
              <div className="col-12">
                <a>Enviando correo de contacto...</a>
              </div>
            </div>
          ) : (
            <div className="row mt-3">
              <div className="col-12">
                <FormInputText
                  name={"name"}
                  control={control}
                  label={"Nombre completo"}
                  type={"text"}
                />
              </div>
              <div className="col-12 col-md-6">
                <FormInputText
                  name={"email"}
                  control={control}
                  label={"Correo electrónico"}
                  type={"email"}
                />
              </div>
              <div className="col-12 col-md-6">
                <FormInputNumber
                  name={"telefono"}
                  control={control}
                  label={"Teléfono de contacto"}
                />
              </div>
              <div className="col-12">
                <FormInputSelect
                  name={"preferencia_contacto"}
                  control={control}
                  label={"¿Cómo prefiere que lo contactemos?"}
                  options={opciones_contacto}
                />
              </div>
              <div className="col-12">
                <FormInputSelect
                  name={"preferencia_horario"}
                  control={control}
                  label={"¿En qué horario prefiere que lo contactemos?"}
                  options={opciones_horario}
                />
              </div>
            </div>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button type="submit" form="contact-me-form">
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReservarFormDialog;
