import React from "react";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MailIcon from "@mui/icons-material/Mail";
import FacebookIcon from "@mui/icons-material/Facebook";
import "../assets/styles/footer.css";

function Footer() {
  return (
    <footer>
      <div
        className="footer-distributed"
        style={{ backgroundColor: "#121212" }}
      >
        <div className="row m-0">
          <div className="col-md-4 p-5">
            <div className="text-white h2 font-weight-bold">Merlop Travel</div>
            <p className="footer-links">
              <a href="./">Home</a> | <a href="./Galeria">Galeria</a> |{" "}
              <a href="./FAQ">Preguntas Frecuentes</a> |{" "}
              <a href="/">Contacto</a>
            </p>
            <small style={{ color: "#8f9296" }}>© 2022 Merlop Travel</small>
          </div>
          <div className="col-md-4 p-5">
            <div className="d-flex">
              <PhoneIphoneIcon style={{ color: "#8f9296" }} />
              <div className="font-weight-bold text-white ml-3">
                MEX +52-443-227-1149
              </div>
            </div>
            <div className="d-flex pt-4">
              <PhoneIphoneIcon style={{ color: "#8f9296" }} />
              <div className="font-weight-bold text-white ml-3">
                USA 312-368-8368
              </div>
            </div>
            <div className="d-flex pt-4">
              <MailIcon style={{ color: "#8f9296" }} />
              <div className="font-weight-bold text-white ml-3">
                merloptravel@gmail.com
              </div>
            </div>
          </div>
          <div className="col-md-4 p-5">
            <div className="font-weight-bold text-white h5">Sobre Nosotros</div>
            <small style={{ color: "#8f9296" }}>
              Somos una compañía por el reverendo Marco Antonio Mercado López
              ofreciendole a sus clientes una atención personalizada en los
              viajes.
            </small>
            <a href="https://www.facebook.com/PadreMarcoMercado">
              <FacebookIcon />
            </a>

            {/* <InstagramIcon />
                    <YouTubeIcon /> */}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
