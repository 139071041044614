import React from "react";
// import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";

import styles from "../../../assets/styles/sobre_nosotros.module.css";
import styles_viajes from "../../../assets/styles/home/viajes_gratis.module.css";

import imgFreeTravel from "../../../assets/images/freetravel.jpg";

export default function ViajesGratis() {
  return (
    <div
      className="viajesAltura mt-5 mt-md-0"
      style={{ backgroundColor: "#121212" }}
    >
      <div className="row d-flex flex-wrap m-0">
        <div
          className="p-5 col-md-5 text-center text-md-right align-self-center"
          style={{ backgroundColor: "#121212", maxHeight: "100%" }}
        >
          <h1 className={`text-white ${styles.titulo}`}>VIAJES GRATIS</h1>
          <p className={`text-white ${styles.texto}`}>
            Con nosotros te ofrecemos la posibilidad de formar tu propio grupo y
            obtener tu viaje gratis, así como de convertirte en un asociado y
            obtener comisiones por trabajar con nosotros. Contactanos para mayor
            información.
          </p>
          <Button
            variant="outlined"
            // className={`btn-lg btn-block ${classes.btn}`}
            className={`btn-lg btn-block ${styles_viajes.btn}`}
          >
            Viajar Gratis
          </Button>
        </div>
        <div className="col-md-7 p-0">
          <img
            className={styles_viajes.imgViajeGratis}
            src={imgFreeTravel}
            alt="Avión Viaje"
          />
        </div>
      </div>
    </div>
  );
}
