import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LightgalleryItem } from "react-lightgallery";
import Masonry from "@mui/lab/Masonry";
import { LazyLoadImage } from "react-lazy-load-image-component";
import api from "../../api/client";

import "lightgallery.js/dist/css/lightgallery.css";
import { LightgalleryProvider } from "react-lightgallery";

export default function GalleryPhotos() {
  const params = useParams();
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    api
      .get("photos/" + params.uuid)
      .then((response) => {
        setPhotos(response.data.data);
        console.log(response.data.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <LightgalleryProvider>
      <div
        style={{
          marginTop: "10vh",
          minHeight: "49vh",
        }}
      >
        <div className="container">
          <div className="d-flex">
            <div className="mx-auto">
              <h1>Galeria</h1>
            </div>
          </div>
          <Masonry columns={3} spacing={2}>
            {photos.map((items, index) => {
              return (
                <div key={index}>
                  <LightgalleryItem
                    group={params.uuid}
                    src={process.env.REACT_APP_LINK + "storage/" + items.image_path}
                    thumb={process.env.REACT_APP_LINK + "storage/" + items.thumbnail_path}
                  >
                    <LazyLoadImage
                      effect="blur"
                      src={process.env.REACT_APP_LINK + "storage/" + items.image_path}
                      placeholderSrc={
                        process.env.REACT_APP_LINK + "storage/" + items.thumbnail_path
                      }
                      alt=""
                      height="100%"
                      width="100%"
                    />
                  </LightgalleryItem>
                </div>
              );
            })}
            {/* <LightgalleryItem src="https://ep01.epimg.net/elpais/imagenes/2019/10/30/album/1572424649_614672_1572453030_noticia_normal.jpg">
            <img
              className="img-fluid"
              src="https://ep01.epimg.net/elpais/imagenes/2019/10/30/album/1572424649_614672_1572453030_noticia_normal.jpg"
            />
          </LightgalleryItem> */}
          </Masonry>
        </div>
      </div>
    </LightgalleryProvider>
  );
}
