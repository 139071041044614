import React from "react";
import {Backdrop as BackdropMUI} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

function Backdrop({ open }) {
  return (
    <BackdropMUI
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </BackdropMUI>
  );
}

export default Backdrop;
