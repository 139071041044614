import React, { useEffect, useState } from "react";
import styles from "../../../assets/styles/sobre_nosotros.module.css";
import ViajesCard from "../../../components/ViajesHomeCard";
import api from "../../../api/client";
import Backdrop from "../../../components/Backdrop";

function ViajesProgramados() {
  const [travels, setTravels] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    api.get("valid_travels")
    .then((response) => {
      setTravels(response.data.data);    
      setLoading(false);
    }).catch((error) => {
      console.log("error", error);
      setLoading(false);
    })
  },[])

  return (
    <div className="container pt-5 viajesAltura">
      <div className="text-center">
        <h1 className={styles.titulo}>VIAJES PROGRAMADOS</h1>
        <p className={styles.texto}>
          Estos son los viajes programados que tenemos agendados, echale un
          vistazo a cada uno y acompañanos a conocer estos lugares.
        </p>
      </div>
      <div className="row pt-1">
      {
        loading ? 
        <a>Cargando...</a>
        :
        travels.map((travel) => {
         return(
          <div className="col-md-4">
          <ViajesCard travel={travel} />
        </div>
         )
        })
      }
        
        {/* <div className="col-md-4 pt-3 pt-md-0">
          <ViajesCard />
        </div>
        <div className="col-md-4 pt-3 pt-md-0">
          <ViajesCard />
        </div> */}
      </div>
    </div>
  );
}

export default ViajesProgramados;
