import React, { useEffect, useState } from "react";
import api from "../../api/client";
import GaleriaCard from "../../components/GaleriaCard";
import GaleriaBackground from "../../assets/images/galeriaBackground.jpg";

// import "../../assets/styles/galeria.css";

export default function Gallery() {
  const [albums, setAlbums] = useState([]);

  useEffect(() => {
    api
      .get("albums")
      .then((response) => {
        setAlbums(response.data.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <div>
      <div>
        <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "50%",
            zIndex: "1",
          }}
        >
          <h1 className="text-white display-2">Galería</h1>
        </div>
        <div className="portadaGaleria">
          <img
            className="parallax-img"
            style={{ height: "50vh" }}
            src={GaleriaBackground}
            alt=""
          />
        </div>
      </div>
      <div className="container py-5">
        <div className="text-center font-weight-bold h1">Nuestros viajes</div>
        <div className="row row-cols-md-3 row-cols-1">
          {albums.map((album, index) => {
            return (
              <div key={index} className="col-md-4 pt-4">
                <GaleriaCard data={album} />
              </div>
            );
          })}
          {/* {albums.map((album) => {
            return (
          <div className="col pt-4">
            <a href={route("galeria.getalbum", [album.uuid])}>
                  <GaleriaCard parentToChild={album} />
                </a>
          </div>
          );
          })} */}
        </div>
      </div>
    </div>
  );
}
