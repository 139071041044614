import React from "react";
// import styles from "../../../assets/css/sobre_nosotros.module.css";
import styles from "../../../assets/styles/sobre_nosotros.module.css";

import img1 from "../../../assets/images/imgb.jpg";
import img2 from "../../../assets/images/imgc.jpg";
import img3 from "../../../assets/images/imge.jpg";
import img4 from "../../../assets/images/imgd.jpg";
import img5 from "../../../assets/images/Night.jpg";
import img6 from "../../../assets/images/imgf.jpg";
import img7 from "../../../assets/images/imga.jpg";
import img8 from "../../../assets/images/imgg.jpg";
import img9 from "../../../assets/images/imgh.jpg";

function Galeria() {
  return (
    <div className="mt-5">
      <div className={`row m-0 ${styles.sizeGaleria}`}>
        <div className="col-md-7" style={{}}>
          <div className="row" style={{ height: "50%" }}>
            <div
              className="col-6"
              style={{
                backgroundImage: `url(${img1})`,
                backgroundSize: "cover",
              }}
            ></div>
            <div
              className="col-6 p-0"
              style={{
                width: "50%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                src={img2}
                alt=""
                style={{
                  width: "100%",
                  height: "50%",
                  objectFit: "cover",
                }}
              />
              <img
                src={img3}
                alt=""
                style={{
                  width: "100%",
                  height: "50%",
                  objectFit: "cover",
                }}
              />
            </div>
          </div>
          <div className="row" style={{ height: "50%" }}>
            <div className="col-6 p-0" style={{ height: "100%" }}>
              <div
                className="col-12 p-0"
                style={{ height: "50%", display: "flex" }}
              >
                <div
                  style={{
                    width: "50%",
                    height: "100%",
                    backgroundImage: `url(${img5})`,
                    backgroundSize: "cover",
                  }}
                ></div>
                <div
                  style={{
                    width: "50%",
                    height: "100%",
                    backgroundImage: `url(${img6})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div
                style={{
                  height: "50%",
                  backgroundImage: `url(${img4})`,
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            <div className="col-6 p-0" style={{ display: "flex" }}>
              <div
                className="col-6 p-0"
                style={{
                  height: "100%",
                  backgroundImage: `url(${img8})`,
                  backgroundSize: "cover",
                }}
              ></div>
              <div className="col-6 p-0">
                <div
                  style={{
                    height: "50%",
                    backgroundImage: `url(${img7})`,
                    backgroundSize: "cover",
                  }}
                ></div>
                <div
                  style={{
                    height: "50%",
                    backgroundImage: `url(${img9})`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="p-5 col-md-5 text-center text-md-left align-self-center order-first order-md-2"
          style={{ backgroundColor: "#121212" }}
        >
          <h1 className={`text-white ${styles.titulo}`}>Galería</h1>
          <p className={`text-white ${styles.texto}`}>
            ¡Visita nuestra galería para visualizar las fotos de nuestros viajes
            mas recientes!
          </p>
        </div>
      </div>
    </div>
  );
}

export default Galeria;
