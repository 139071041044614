import React from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import { useNavigate } from "react-router-dom";

import "../assets/styles/viajes_card.css";
import "../assets/styles/animations.css";

export default function GaleriaCard({ data }) {
  const navigate = useNavigate();

  return (
    <Card
      className="growCard"
      onClick={() => navigate("/galeria/" + data.uuid)}
    >
      <CardActionArea>
        <div
          className="viajesCard"
          style={{
            backgroundImage:
              `linear-gradient(
                            rgba(0,0,0, .7),
                            rgba(0,0,0, .7)
                        ),url(` +
              process.env.REACT_APP_LINK +
              `storage/` +
              data.portrait_img +
              `)`,
          }}
        >
          <div className="text-white text-center">
            <h2 className="textViajesCard">{data.title}</h2>
            <h4 className="textViajesCard">{data.date}</h4>
          </div>
          <div className="text-white textViajesCard">Ver Fotos</div>
        </div>
      </CardActionArea>
    </Card>
  );
}
