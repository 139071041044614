import React from "react";
import { Controller } from "react-hook-form";
import MuiPhoneNumber from "material-ui-phone-number";
import { FormControl } from "@mui/material";

function FormInputNumber({ name, control, label }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value, ref },
        fieldState: { error },
        formState,
      }) => (
        <FormControl sx={{ m: 1, width: "100%" }}>
          <MuiPhoneNumber
            name="phone"
            label="Phone Number"
            data-cy="user-phone"
            defaultCountry={"us"}
            value={value}
            onChange={onChange}
          />
        </FormControl>
      )}
    />
  );
}

export default FormInputNumber;
